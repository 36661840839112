import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogUrl =
    "https://selbysellssd.com/what-has-changed-in-the-approval-process-for-buying-a-home";

  return (
    <LayoutWrapper
      title="What Has Changed in the Approval Process for Buying a Home?"
      desc="Learn about the current state of housing market and its approval process. This is essential to finding and securing the home of your dreams. Read more now!"
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              What Has Changed in the Approval Process for Buying a Home?
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By Sarah Harris
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">Dec 15, 2023</p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={blogUrl} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_What Has Changed in the Approval Process for Buying a Home.jpg"
              alt="blogpost"
            />

            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Though the home-buying process maintains certain consistencies over time, the market
                will fluctuate from year to year on both the national and local levels. That’s why
                knowing the current state of the housing market and its approval process is
                essential to finding (and securing) the home of your dreams.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                What has changed in the home-buying process? Throughout 2023, national inflation
                from the previous year caused a significant slowdown in home sales. Price
                appreciation also decelerated through 2023 despite substantial increases in 2022.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                As 2024 begins, the inflation of the past two years has decreased quite a bit,
                although prices of homes and mortgage rates are still on the higher end.{" "}
                <a
                  href="https://selbysellssd.com/sell-your-san-diego-home"
                  target="_Blank"
                  className="text-blue-500"
                >
                  Sellers
                </a>
                continue to have more power than buyers in the early 2024 market. However,{" "}
                <a
                  href="https://selbysellssd.com/buy-house-san-diego"
                  target="_Blank"
                  className="text-blue-500"
                >
                  buyers
                </a>
                can still find an excellent deal on an incredible home if they play their cards
                right.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Tips for Securing Your Dream Home in 2024
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The San Diego approval process for buying a home in 2024 has shifted in multiple
                ways, but much of the best advice for home buying still stands. The best way to
                start is to understand you homeownership goals.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Once you know what you’re looking to get from purchasing a home, you can follow
                these tips to get closer to your goals.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Understand Your Local Market
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                The housing market can be affected by so many factors. While national changes like
                inflation or recession can shift dynamics in local real estate markets around the
                country, the current state of housing in your area could be affected by changes to
                local laws, new builds in the neighborhood, zoning updates, and more.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Most buyers put in offers on homes during spring or early summer, but the current
                climate in your real estate market, as well as your financial preparedness, are more
                important than the specific timing. You should have your financial resources in
                order before you pull the trigger on a home purchase.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Additionally, watch home prices and how they fluctuate throughout the year. If they
                begin to spike later in 2024, you may want to hold off on your home-buying plans
                until things cool off.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Anticipate Extra Costs
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Every potential homebuyer knows that the down payment will be one of the largest
                upfront expenses when buying a home. However, it’s not the only chunk of money
                you’ll be expected to present during the approval process.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Closing costs are another significant financial aspect of purchasing a home. These
                costs vary depending on your state and the nature of the transaction, but you should
                expect to pay a few thousand dollars in addition to your down payment to close on
                your home.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Get Your Credit in Order
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Having the capital necessary to buy your home is essential to getting approved on
                your offer. However, the state of your credit can also affect whether your
                application is accepted.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                Most loaning agencies will require a credit score of at least 620, and many mortgage
                loan companies will only consider applicants with scores in the 700s and above. If
                your credit score could use some padding, focus on increasing it before you try to
                buy a home.
              </p>
              <h2 className="font-IbarraRealNova text-d-30 text-[#1A1A1A] font-[600] text-left mb-[16px]">
                Hire a Trusted Real Estate Pro
              </h2>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                No one can counsel you on the ins and outs of real estate than an agent with years
                of experience in your area. That’s why you should trust the{" "}
                <a href="https://selbysellssd.com/" target="_Blank" className="text-blue-500">
                  Selby Team
                </a>{" "}
                with your next home purchase or sale in San Diego.
              </p>
              <p className="text-d-5lg text-[#666666] font-Quicksand font-[500] mb-[30px] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg">
                <ContactSlideoutLink text="Contact us today" /> to get started on finding your new
                home.
              </p>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={blogUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
